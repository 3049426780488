import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faTerminal } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import { TableLite, Table, SORT } from '../components/Table'
import { toast } from 'react-toastify';
import { axiosInstance } from '../common/utils';
import moment from 'moment';

const ModalCommand = (props) => {
  const { t } = useTranslation();
  const [ loading, setLoading ] = useState(false)
  const [ commandList, setCommandList ] = useState([])
  const [ commandTypeList, setCommandTypeList ] = useState([])
  const commandType = useRef(null)
  const parameters = useRef({})
  const headers = [
    { key: 'command_type', title: t('command_type'), sort: SORT.DEFAULT },
    { key: 'timestamp_created', title: t('timestamp_created'), sort: SORT.DEFAULT,
      rendering: (uuid, item) => 
        <div className='w-100 text-center'>{moment(new Date(item)).format('DD/MM/YYYY HH:mm')}</div>
    },
    { key: 'timestamp_executed', title: t('timestamp_executed'), sort: SORT.DEFAULT,
      rendering: (uuid, item) => 
        <div className='w-100 text-center'>{item?moment(new Date(item)).format('DD/MM/YYYY HH:mm'):'-'}</div>
    },
    { key: 'result_text', title: t('result_text'), sort: SORT.DEFAULT },
    { key: 'status', title: t('status'), sort: SORT.DEFAULT, className: 'text-center',
      rendering: (uuid, item) => {
        let className = ''
        if( item === 'pending' ) className = 'bg-primary'
        if( item === 'executed' ) className = 'bg-success'
        if( item === 'executed_with_issues' ) className = 'bg-warning'
        if( item === 'error' ) className = 'bg-danger'
        return <div className='w-100 text-center'>
                  <span className={`badge ${className}`}>{item.toUpperCase()}</span>
                </div>
      }
    },
  ]

  const refresh = async (parameters = {}) => {
    try{
      if (props.dataHub == null) return
      if (props.dataHub.uuid == null) return
      setLoading(true)
      const urlParams = new URLSearchParams(parameters).toString();
      const response = await axiosInstance.get(`/api/v1/data_hub_commands/?${urlParams.length>0?`${urlParams}`:''}`);
      setCommandList({ count: response.data.length, results: response.data})
      setLoading(false)
    }catch(e){   
      console.log("e ", e)
      toast.error(t('request_failed'))   
    }
  }

  useEffect( () => {
    parameters.current = { data_hub: props.dataHub.uuid }
    refresh(parameters.current)
  }, [props.dataHub])

  useEffect( () => {
    ( async () => {
      try{
        const response = await axiosInstance.get(`/api/v1/data_hub_commands/type/`);
        setCommandTypeList([...response.data])
        commandType.current = response.data[0]
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();
  }, [])

  const onSelectCommandType = (e) => {
    commandType.current = e.target.value
  }
  const onSendCommand = async () => {
    try{
      // Create send command
      const body = {
        data_hub: props.dataHub.uuid,
        command_type: commandType.current
      }
      await axiosInstance.post(`/api/v1/data_hub_commands/`, body);

      // Refresh table
      refresh(parameters.current)
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const onSortClick = async (key, direction, pageSize, page) => {
    const prefix = direction === SORT.DOWN?"-":""
    parameters.current = { ...parameters.current, ordering : `${prefix}${key}` }
    refresh(parameters.current)
  }

  return <Modal show={props.show} onHide={() => props.onCancel()} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{t('data_hub_command')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: '500px' }} className='standalone-table-container'>

          <TableLite headers={headers} 
            data={commandList}
            onSortClick={(key, direction, pageSize, page) => onSortClick(key, direction, pageSize, page)} 
            actionsEnabled={false} 
            loading={loading} 
            style={{ minHeight: '80%'}} 
            onClickRow={() => true} />


            <div className="mt-3">
              <div className="fw-bold">{t('send_command')}</div>
              <div className="d-flex w-50 mt-2">
                <select className="form-control"  onClick={ (e) => onSelectCommandType(e)}>
                  {commandTypeList.map( (item, idx) => 
                    <option key={idx}>{item}</option>
                  )}
                </select>
                <button type="button" className="standalone-button btn btn-primary ms-3" 
                  onClick={() => onSendCommand()}>
                  {t('send')}
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="standalone-button btn btn-primary" 
              onClick={() => props.onProceed()}>
              {t('ok')}
            </button>
          </Modal.Footer>
        </Modal>
}

const ModalDialogConfirm = (props) => {
  const { t } = useTranslation();
  const [triggerAnimation, setTriggerAnimation] = useState(false)

  const onCancel = (e) => {
    e.stopPropagation()
    props.onCancel(e)
  }

  const onProceed = (e) => {
    e.stopPropagation()
    props.onProceed(e)
  }

  useEffect( () => {
    if( props.show === false ) setTriggerAnimation(false)
    else setTimeout( () => setTriggerAnimation(true), 200)
  }, [props.show])

  const showClass = triggerAnimation?"standalone-modal-dialog show":"standalone-modal-dialog";
  return <ModalDialog className={props.show?showClass:'d-none'}>
            <Modal.Header >
              <Modal.Title>{t('delete')} {t('data_hub_note')}</Modal.Title>
              <button type="button" className="btn-close" aria-label="Close" 
                onClick={(e) => onCancel(e)}>                  
              </button>
            </Modal.Header>
            <Modal.Body>
              {t('are_you_sure')}
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary"
                onClick={(e) => onCancel(e)}>
                {t('cancel')}
              </button>
              <button type="button" className="standalone-button btn btn-primary"
                onClick={(e) => onProceed(e)}>
                {t('ok')}
              </button>
            </Modal.Footer>
          </ModalDialog>
}

const ModalDialogDataHubNote = (props) => {
  const { t } = useTranslation();
  const [triggerAnimation, setTriggerAnimation] = useState(false)
  const [ noteTypeList, setNoteTypeList ] = useState([])
  const [ note, setNote ] = useState({ note_type: '', text: ''})
  const onCancel = (e) => {
    e.stopPropagation()
    props.onCancel(e)
  }

  useEffect( () => {
    if( props.show === false ) setTriggerAnimation(false)
    else setTimeout( () => setTriggerAnimation(true), 200)
  }, [props.show])

  useEffect( () => {
    ( async () => {
      try{
        const response = await axiosInstance.get(`/api/v1/data_hub_notes/type/`);
        setNoteTypeList([...response.data])
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();
  }, [])

  useEffect( () => {
    ( async () => {
      if(props.targetUuid == null){
        setNote({ type: noteTypeList[0], text: '', data_hub: props.dataHub.uuid})
        return
      }

      try{        
        const response = await axiosInstance.get(`/api/v1/data_hub_notes/${props.targetUuid}/`);
        setNote({...response.data})
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();

  }, [props.targetUuid])

  const onClickSave = async () => {
    try{
      // Create
      if( props.targetUuid == null){
        await axiosInstance.post(`/api/v1/data_hub_notes/`, {...note});
      }

      // Update
      if( props.targetUuid ){
        await axiosInstance.put(`/api/v1/data_hub_notes/${props.targetUuid}/`, {...note});
      }

      // Call parent
      props.onSave();
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const showClass = triggerAnimation?"standalone-modal-dialog show":"standalone-modal-dialog";
  const operation = props.targetUuid?t('edit'):t('add')
  return <ModalDialog className={props.show?showClass:'d-none'}>
            <Modal.Header >
              <Modal.Title>{operation} {t('data_hub_note')}</Modal.Title>
              <button type="button" className="btn-close" aria-label="Close" 
                onClick={(e) => onCancel(e)}>                  
              </button>
            </Modal.Header>
            <Modal.Body>
              <div>
                <label className="form-label">{t('note_text')}</label>
                <input type="text" className="form-control"  value={note.text}
                  onChange={(e) => setNote({ ...note, text: e.target.value})}></input>                  
              </div>
              <div className="mt-3">
                <label className="form-label">{t('note_type')}</label>
                <select className="form-control" value={note.note_type}
                 onChange={ (e) => setNote({ ...note, note_type: e.target.value})}>
                  {noteTypeList.map( (item, idx) => 
                    <option key={idx}>{item}</option>
                  )}
                </select>              
              </div>

            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary"
                onClick={(e) => onCancel(e)}>
                {t('cancel')}
              </button>
              <button type="button" className="standalone-button btn btn-primary"
                onClick={(e) => onClickSave(e)}>
                {t('save')}
              </button>
            </Modal.Footer>
          </ModalDialog>
}

const ModalComment = (props) => {
  const { t } = useTranslation();
  const [ loading, setLoading ] = useState(false)
  const [ noteList, setNoteList ] = useState([])

  const [ showConfirmDelete, setShowConfirmDelete ] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const parameters = useRef({})
  const targetUuid = useRef(null)

  const headers = [
    { key: 'timestamp_created', title: t('timestamp_created'), sort: SORT.DEFAULT,
      rendering: (uuid, item) => 
        <div className='w-100 text-center'>{moment(new Date(item)).format('DD/MM/YYYY HH:mm')}</div>
    },
    { key: 'timestamp_updated', title: t('timestamp_updated'), sort: SORT.DEFAULT, className: 'text-center',
      rendering: (uuid, item) => 
        <div className='w-100 text-center'>{moment(new Date(item)).format('DD/MM/YYYY HH:mm')}</div>
    },
    { key: 'text', title: t('text'), sort: SORT.DEFAULT, className: 'text-center' },
    { key: 'user_updated', title: t('user_updated'), sort: SORT.DEFAULT, className: 'text-center' },    
    { key: 'note_type', title: t('note_type'), sort: SORT.DEFAULT, className: 'text-center',
      rendering: (uuid, item) => {
        let className = ''
        if( item === 'NOTE' ) className = 'bg-primary'
        if( item === 'DONE' ) className = 'bg-success'
        if( item === 'TODO' ) className = 'bg-warning'
        return <div className='w-100 text-center'>
                  <span className={`badge ${className}`}>{item.toUpperCase()}</span>
                </div>
      }
    },
  ]

  const refresh = async (parameters = {}) => {
    try{
      if (props.dataHub == null) return
      if (props.dataHub.uuid == null) return
      setLoading(true)
      const urlParams = new URLSearchParams(parameters).toString();
      const response = await axiosInstance.get(`/api/v1/data_hub_notes/?expand=user_updated&${urlParams.length>0?`${urlParams}`:''}`);
      
      const resultsLocal = response.data.map( item => ({
        ...item, user_updated: item.user_updated.email
      }))

      setNoteList({ count: response.data.length, results: resultsLocal})
      setLoading(false)
    }catch(e){   
      console.log("e ", e)
      toast.error(t('request_failed'))   
    }
  }

  useEffect( () => {
    parameters.current = { data_hub: props.dataHub.uuid }
    refresh(parameters.current)
    
  }, [props.dataHub])

  const onShowConfirmDelete = (uuid) => {
    targetUuid.current = uuid;
    setShowConfirmDelete(true)
  }

  const onHideConfirmDelete = () => {
    setShowConfirmDelete(false)
  }

  const onProceedDelete = async (e) => {
    try{
      setShowConfirmDelete(false)

      await axiosInstance.delete(`/api/v1/data_hub_notes/${targetUuid.current}/`);

      // Refresh
      refresh(parameters.current)

    }catch(e){
      toast.error(t('request_failed'))   
    }
  }

  const onClickEdit = (uuid) => {
    targetUuid.current = uuid;
    setShowDetail(true)
  }

  const onClickAdd = () => {
    targetUuid.current = null;
    setShowDetail(true)
  }

  const onSortClick = async (key, direction, pageSize, page) => {
    const prefix = direction === SORT.DOWN?"-":""
    parameters.current = { ...parameters.current, ordering : `${prefix}${key}` }
    refresh(parameters.current)
  }


  return <Modal show={props.show} onHide={() => props.onCancel()} centered  size="lg">
          <div style={{ position:'relative'}}>
            <Modal.Header closeButton>
              <Modal.Title>{t('data_hub_note')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '600px' }} className=''>

              <div className="d-flex flex-column h-100">
                <div className='mt-3 mb-3 d-flex justify-content-between'>
                  <button type="button" className="btn btn-primary standalone-button"
                    onClick={() => onClickAdd()}>{t('add_new')}</button>
                </div>
                <div className='flex-grow-1'>
                  <TableLite headers={headers} 
                      data={noteList}
                      onSortClick={(key, direction, pageSize, page) => onSortClick(key, direction, pageSize, page)} 
                      onClickDelete={(uuid) => onShowConfirmDelete(uuid)}
                      loading={loading} 
                      onClickRow={(uuid) => onClickEdit(uuid)} />
                </div>
              </div>
            <ModalDialogConfirm
              show={showConfirmDelete} 
              onCancel={() => onHideConfirmDelete()}
              onProceed={() => onProceedDelete()}
              />
            <ModalDialogDataHubNote 
              show={showDetail}
              onSave={() => {setShowDetail(false); refresh(parameters.current)} }
              onCancel={() => setShowDetail(false)}
              targetUuid={targetUuid.current}
              dataHub={props.dataHub}
              />
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="standalone-button btn btn-primary" 
                onClick={() => props.onProceed()}>
                {t('ok')}
              </button>
            </Modal.Footer>
            </div>
          </Modal>
}

export const DataHubControlPanel = (props) => {
  const { t } = useTranslation();
  const [ showCommand, setShowCommand ]  = useState(false)
  const [ showComment, setShowComment ]  = useState(false)


  return <div className="text-center w-50 border p-3 rounded"  style={{ position: 'relative'}}>
            <h3>{t('data_hub_control_panel')}</h3>
            <div style={{ position: 'absolute', right: "10px", top: '25px'}}>                  
              <div>
                <FontAwesomeIcon icon={faTerminal} role="button" 
                  onClick={() => setShowCommand(!showCommand)} />
              </div>    
              <div className="mt-1">
                <FontAwesomeIcon icon={faFileLines} role="button" 
                  onClick={() => setShowComment(!showComment)} />
              </div>
            </div>
            <div className="mt-3">

              <div className="text-start">
                <label className="w-33 fw-bold">{t('name')}:</label>
                <label>{props.dataHub.name}</label>
              </div>
              <div className="text-start">
                <label className="w-33 fw-bold">{t('mac_address')}:</label>
                <label>{props.dataHub.mac_address}</label>
              </div>
              <div className="text-start">
                <label className="w-33 fw-bold">{t('is_online')}</label>
                <label>{props.dataHub.is_online?"YES":"NO"}</label>
              </div>
              <div className="text-start">
                <label className="w-33 fw-bold">{t('company_site')}:</label>
                <label>{props.dataHub.company_site.name}</label>
              </div>
              <div className="text-start">
                <label className="w-33 fw-bold">{t('company')}:</label>
                <label>{props.dataHub.company_site.company.name}</label>
              </div>
            </div>

            <ModalCommand show={showCommand} 
              onCancel={() => setShowCommand(false)} 
              onProceed={() => setShowCommand(false)} 
              dataHub={props.dataHub} />
            <ModalComment show={showComment} 
              onCancel={() => setShowComment(false)} 
              onProceed={() => setShowCommand(false)} 
              dataHub={props.dataHub} />
          </div>
}