const routes = {
  welcome: {
    title: "welcome",
    url: "/"
  },
  login: {
    title: "login",
    url: "/"
  },
  dashboard: {
    title: "dashboard",
    url: "/dashboard"
  },
  user: {
    title: "user",
    url: "/dashboard"
  },
  company: {
    title: "company",
    url: "/dashboard/company"
  },
  company_site: {
    title: "company_site",
    url: (uuid) => `/dashboard/company/${uuid}/site`,
    regex: /\/dashboard\/.*\/site/
  },
  influxdb_server: {
    title: "influxdb_server",
    url: "/dashboard/influxdb"
  },
  influxdb_credential: {
    title: "influxdb_credential",
    // url: "/dashboard/influxdb/credential"
    url: (uuid) => `/dashboard/influxdb/${uuid}/credential`,
    regex: /\/dashboard\/.*\/credential/

  },
  data_hub: {
    title: "data_hub",
    url: "/dashboard/datahub"
  },
  sensor: {
    title: "sensor",
    url: (uuid) => `/dashboard/${uuid}/sensor`,
    regex: /\/dashboard\/.*\/sensor/
  },
  scrapper: {
    title: "scrapper",
    url: `/dashboard/scrapper`
  },
};
export default routes;
