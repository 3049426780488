import { useTranslation } from "react-i18next";
import { Table, SORT } from '../components/Table'
import { useEffect, useState, useRef } from 'react';
import { axiosInstance } from '../common/utils';
import Modal from 'react-bootstrap/Modal';
import { ModalConfirmation } from '../components/ModalConfirmation';
import { ModalWarning } from '../components/ModalWarning';
import Select from 'react-select';
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import routes from '../common/routes'
import { useNavigate, useParams } from "react-router-dom";


const CompanySiteModal = (props) => {
  const { t } = useTranslation();
  const [ companySite, setCompanySite ] = useState({
    name: '', company: '', address: ''
  });
  const [ companyList, setCompanyList ] = useState([])
  const user = useSelector(state => state.main.user)


  const onSave = async () => {
    try{    
      // Create
      if( props.targetUuid == null){
        await axiosInstance.post(`/api/v1/company_sites/`, {...companySite});
      }

      // Update
      if( props.targetUuid ){
        await axiosInstance.put(`/api/v1/company_sites/${props.targetUuid}/`, {...companySite});
      }

      // Call parent
      props.onSave();
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }
  
  useEffect( () => {
    ( async () => {
      try{
        if( props.targetUuid == null){
          setCompanySite({ name: '', company: '', address: ''})
          return
        }


        if( props.targetUuid){
          const response = await axiosInstance.get(`/api/v1/company_sites/${props.targetUuid}/?expand=company`);
          const { name, address, company } = response.data;
          setCompanySite({ name, address, company: company.uuid })
          return
        }
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();

  }, [props.show, props.targetUuid, t])

  useEffect( () => {
    ( async () => {
      try{      
        const response = await axiosInstance.get(`/api/v1/companies/`);
        setCompanyList( response.data )
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();
  }, [user, t])

  const comanyNameList = companyList.map( item => ({value: item.uuid,label:item.name}) )
  const operation = props.targetUuid?t('edit'):t('add')
  return (<Modal show={props.show} onHide={() => props.onCancel()} centered>
            <Modal.Header closeButton>
              <Modal.Title>{operation} {t('user')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>{t('name')}</label>
                <input type="text" className="form-control mt-2" 
                  value={companySite.name} onChange={(e) => setCompanySite({...companySite, name: e.target.value})} />
              </div>

              <div className="form-group mt-2">
                <label>{t('company')}</label>
                <Select options={comanyNameList} 
                        onChange={(selected) => setCompanySite({...companySite, company: selected.value}) }
                        className="company-site"
                        classNamePrefix="company-site"
                        styles={{
                          menuPortal: styles => ({...styles, zIndex: 10000 })
                        }}
                        menuPortalTarget={document.body} 
                        value={comanyNameList.filter( item => item.value === companySite.company)} />
              </div>

              <div className="form-group mt-2">
                <label>{t('address')}</label>
                <input type="text" className="form-control mt-2" 
                  value={companySite.address} onChange={(e) =>  setCompanySite({...companySite, address: e.target.value})}/>                
              </div>

            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary"
                onClick={() => props.onCancel()}>
                {t('cancel')}
              </button>
              <button type="button" className="standalone-button btn btn-primary"
                onClick={() => onSave()}>
                {t('save')}
              </button>
            </Modal.Footer>
          </Modal>)
}

export const CompanySite = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({ count: 0, results: []})
  const [loading, setLoading] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const actionRef = useRef('')
  const targetUuid = useRef(null)
  const pageSizeRef = useRef(10);
  const offsetRef = useRef(0);
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const user = useSelector(state => state.main.user)
  
  const navigate = useNavigate();
  const { company } = useParams();
  const parameters = useRef({ company: company})

  const headers = [
    { key: 'name', title: t('name'), sort: SORT.DEFAULT },
    { key: 'company', title: t('company'), sort: SORT.DEFAULT },
    { key: 'address', title: t('address'), sort: SORT.DEFAULT },
    { key: 'calc_data_hubs', title: t('calc_data_hubs'), sort: SORT.DEFAULT, className: 'text-center',
      rendering: (uuid, item) => { 
      return <div className='text-center'>
              <span className="badge bg-secondary" 
              onClick={() => navigate( routes.data_hub.url + `?company_site=${uuid}`)}
              >
              {item} {t('data_hub')}
              </span>
            </div>
      }
    },
  ]

  const refresh = async (limit, offset, parameters = {}) => {
    try{
      setLoading(true)
      const urlParams = new URLSearchParams(parameters).toString();
      const response = await axiosInstance.get(`/api/v1/company_sites/?limit=${limit}&expand=company&offset=${offset}${urlParams.length>0?`&${urlParams}`:''}`);
      const resultsLocal = response.data.results.map( item => ({
        ...item, company: item.company.name
      }))
      setData({ count: response.data.count, results: resultsLocal })
      setLoading(false)
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  useEffect( () => {
    console.log("parameters ", parameters.current)
    if(user)
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }, [user]);

  // -------------------
  // Handlers
  // -------------------

  const onPageChange = async (pageSize, page) => {
    // Store parameters
    pageSizeRef.current = pageSize;
    offsetRef.current = pageSize*page;
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSortClick = async (key, direction, pageSize, page) => {
    const prefix = direction === SORT.DOWN?"-":""
    const keyLocal = key === "company"?"company__name":key
    parameters.current = { ...parameters.current, ordering : `${prefix}${keyLocal}` }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSearch = (pattern, pageSize, page) => {
    parameters.current = { ...parameters.current, search : pattern }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onClickEdit = (uuid) => {
    targetUuid.current = uuid;
    setShowDetail(true)
  }

  const onClickDelete = (uuid) => {
    const obj = data.results.find( item => item.uuid === uuid );
    if(obj.calc_data_hubs > 0 ){
      setShowWarning(true)
      return
    }

    targetUuid.current = uuid;
    setShowConfirmation(true)
  }

  const onDeleteProceed = async () => {
    try{
      setShowConfirmation(false)

      await axiosInstance.delete(`/api/v1/company_sites/${targetUuid.current}/`);

      // Refresh
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const onClickAdd = () => {
    targetUuid.current = null;
    setShowDetail(true)
  }

  const onSaveCompleted = () => {
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
    setShowDetail(false)
  }


  return <div className='h-100'>
          <div className='d-flex w-100' role="button">
            <div className="h-100" onClick={() => navigate(routes.company.url)}>
              <FontAwesomeIcon role="button" icon={faRotateLeft} />
              <label role="button"  className="ms-2">{t('back')}</label>
            </div>
          </div>


          <Table data={data} headers={headers} 
            onSortClick={ (key, direction, pageSize, page) => onSortClick(key, direction, pageSize, page)} 
            onClickRow={(id) => onClickEdit(id)} 
            onClickDelete={(id) => onClickDelete(id)} 
            onClickAdd={() => onClickAdd()} 
            onPageChange={(pageSize, page) => onPageChange(pageSize, page)} 
            loading={loading} 
            onSearch={(pattern, pageSize, page) => onSearch(pattern, pageSize, page)} />

          <ModalConfirmation title={`${actionRef.current} ${t('company_site')}`} 
            onCancel={() => setShowConfirmation(false)} 
            onProceed={() => onDeleteProceed()}
            show={showConfirmation} />

          <ModalWarning title={t('warning')}
            message={t('dependent_data_hubs')}
            show={showWarning} 
            onProceed={() => setShowWarning(false)}
            />

          <CompanySiteModal show={showDetail} 
            onCancel={() => setShowDetail(false)}
            onSave={() => onSaveCompleted()}
            targetUuid={targetUuid.current}
            />

        </div>
}