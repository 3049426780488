import { Dashboard } from '../components/Dashboard'
import { useTranslation } from "react-i18next";
import { Table, SORT } from '../components/Table'
import { useEffect, useRef, useState } from 'react';
import { axiosInstance } from '../common/utils';
import Modal from 'react-bootstrap/Modal';
import { ModalConfirmation } from '../components/ModalConfirmation';
import { toast } from 'react-toastify';
import {useSelector, useDispatch} from 'react-redux'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff, faLink } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import routes from '../common/routes'
import './DataHub.css'
import Switch from "react-switch";

const DataHubModal = (props) => {
  const { t } = useTranslation();
  const [ dataHub, setDataHub ] = useState({
    name: '', 
    company_site: '',
    influxdb_for_health_info: '', influxdb_for_health_info_bucket: '', influxdb_for_health_info_org: '',
    influxdb_for_data: '', influxdb_for_data_bucket: '', influxdb_for_data_org: '',
    global_polling_rate_ms: '',
    is_online: false,
    mac_address: '',
    mac_address_array: [],
  });
  const [ companySiteList, setCompanySiteList ] = useState([])
  const [ influxDBCredentialList, setInfluxDBCredentialList ] = useState([])
  const [ macAddressValid, setMacAddressValid ] = useState(true)
  const user = useSelector(state => state.main.user)

  const onSave = async () => {
    try{
      // Create
      if( props.targetUuid == null){
        await axiosInstance.post(`/api/v1/data_hubs/`, {...dataHub});
      }

      // Update
      if( props.targetUuid ){
        await axiosInstance.put(`/api/v1/data_hubs/${props.targetUuid}/`, {...dataHub});
      }

      // Call parent
      props.onSave();
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))
    }
  }

  useEffect( () => {
    ( async () => {
      try{
        if( props.targetUuid == null){
          setDataHub({
            name: '', 
            company_site: '',
            influxdb_for_health_info: '', influxdb_for_health_info_bucket: '', influxdb_for_health_info_org: '',
            influxdb_for_data: '', influxdb_for_data_bucket: '', influxdb_for_data_org: '',
            global_polling_rate_ms: '',
            is_online: false,
            mac_address: '',
            mac_address_array: []
          })
          return
        }

        if( props.targetUuid ){
          const response = await axiosInstance.get(`/api/v1/data_hubs/${props.targetUuid}/`);
          const obj = {...response.data}
          obj.mac_address_array = obj.mac_address.split(':')
          obj.mac_address_array = obj.mac_address_array.map( item => ({value:item, valid: true}))
          setDataHub({...obj})
          return
        }
      }catch(e){   
        console.log("errror ", e)
        toast.error(t('request_failed'))   
      }
    })();

  }, [props.show, props.targetUuid, t])

  useEffect( () => {
    ( async () => {
      try{      
        let response = await axiosInstance.get(`/api/v1/company_sites/?expand=company`);
        setCompanySiteList( response.data )

        response = await axiosInstance.get(`/api/v1/influxdb_credentials/`);
        setInfluxDBCredentialList( response.data )
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();
  }, [user, t])


  const validateMacAddressItem = (value) => {
    return /^[A-Fa-f0-9]+$/i.test(value);
  }

  const onChangeMacAddress = (e) => {
    const mac_address = e.target.value    
    let mac_address_array = mac_address.split(':')
    mac_address_array = mac_address_array.map( item => ({value:item, valid: validateMacAddressItem(item)}))
    setDataHub({...dataHub, mac_address: mac_address, mac_address_array: mac_address_array, macAddressValid})
  }

  const onValidateMacAddress = (e) => {
    const mac_address = e.target.value    
    let mac_address_array = mac_address.split(':')
    console.log("split ", mac_address.split(':'))
    // Validating
    let macAddressValid = true
    if( mac_address_array.length !== 6){
      setMacAddressValid(false)
      return
    }
    if( macAddressValid === true){
      for( const item of mac_address.split(':') ){
        console.log("item ", item)
        if( item.length > 2 || validateMacAddressItem(item) === false ){
          setMacAddressValid(false)
          return
        }
      }
    }
    setMacAddressValid(true)
  }


  const comanySiteNameList = companySiteList.map( item => ({value: item.uuid, label: `${item.name} - ${item.company.name}`}) )
  const influxDBCredentialNameList = influxDBCredentialList.map( item => ({value: item.uuid,label:item.name}) )
  const operation = props.targetUuid?t('edit'):t('add')
  return (<Modal show={props.show} onHide={() => props.onCancel()} centered size='lg'>
            <Modal.Header closeButton>
              <Modal.Title>{operation} {t('data_hub')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '500px', height: 'initial'}} className='standalone-table-container d-flex'>
              <div className='w-50 me-3'>

                <div className="form-group">
                  <label>{t('name')}</label>
                  <input type="text" className="form-control mt-2" 
                    value={dataHub.name} onChange={(e) => setDataHub({...dataHub, name: e.target.value})} />
                </div>

                <div className="form-group mt-2">
                  <label>{t('company_site')}</label>
                  <Select options={comanySiteNameList} 
                          onChange={(selected) => setDataHub({...dataHub, company_site: selected.value}) }
                          className="company-site mt-2"
                          classNamePrefix="company-site"
                          styles={{
                            menuPortal: styles => ({...styles, zIndex: 10000 })
                          }}
                          menuPortalTarget={document.body} 
                          value={comanySiteNameList.filter( item => item.value === dataHub.company_site)} />
                </div>

                <div className='border mt-3 p-2 rounded'>

                  <div className="form-group mt-2">
                    <label>{t('influxdb_for_health_info')}</label>
                    <Select options={influxDBCredentialNameList} 
                            onChange={(selected) => setDataHub({...dataHub, influxdb_for_health_info: selected.value}) }
                            className="company-site mt-2"
                            classNamePrefix="company-site"
                            styles={{
                              menuPortal: styles => ({...styles, zIndex: 10000 })
                            }}
                            menuPortalTarget={document.body} 
                            value={influxDBCredentialNameList.filter( item => item.value === dataHub.influxdb_for_health_info)} />
                  </div>

                  <div className="form-group mt-2">
                    <label>{t('influxdb_for_health_info_org')}</label>
                    <input type="text" className="form-control mt-2" 
                      value={dataHub.influxdb_for_health_info_org} onChange={(e) => setDataHub({...dataHub, influxdb_for_health_info_org: e.target.value})} />
                  </div>

                  <div className="form-group mt-2">
                    <label>{t('influxdb_for_health_info_bucket')}</label>
                    <input type="text" className="form-control mt-2" 
                      value={dataHub.influxdb_for_health_info_bucket} onChange={(e) => setDataHub({...dataHub, influxdb_for_health_info_bucket: e.target.value})} />
                  </div>

                </div>


              </div>


              <div className='w-50 ms-3'>


                <div className="form-group">
                  <label>{t('global_polling_rate_ms')}</label>
                  <input type="number" className="form-control  mt-2" 
                    value={dataHub.global_polling_rate_ms} onChange={(e) => setDataHub({...dataHub, global_polling_rate_ms: e.target.value})} />
                </div>

                <div className="form-group mt-2">
                  <label>{t('mac_address')}</label>
                  <input type="text" className={macAddressValid?`form-control mt-2`:"form-control mt-2 is-invalid"}
                    value={dataHub.mac_address} onChange={(e) => {onChangeMacAddress(e); onValidateMacAddress(e)}} />
                </div>

                <div className='border mt-3 p-2 rounded'>
                  <div className="form-group mt-2">
                    <label>{t('influxdb_for_data')}</label>
                    <Select options={influxDBCredentialNameList} 
                            onChange={(selected) => setDataHub({...dataHub, influxdb_for_data: selected.value}) }
                            className="company-site mt-2"
                            classNamePrefix="company-site"
                            styles={{
                              menuPortal: styles => ({...styles, zIndex: 10000 })
                            }}
                            menuPortalTarget={document.body} 
                            value={influxDBCredentialNameList.filter( item => item.value === dataHub.influxdb_for_data)} />
                  </div>

                  <div className="form-group  mt-2">
                    <label>{t('influxdb_for_data_org')}</label>
                    <input type="text" className="form-control mt-2" 
                      value={dataHub.influxdb_for_data_org} onChange={(e) => setDataHub({...dataHub, influxdb_for_data_org: e.target.value})} />
                  </div>

                  <div className="form-group mt-2">
                    <label>{t('influxdb_for_data_bucket')}</label>
                    <input type="text" className="form-control mt-2" 
                      value={dataHub.influxdb_for_data_bucket} onChange={(e) => setDataHub({...dataHub, influxdb_for_data_bucket: e.target.value})} />
                  </div>
                </div>

              </div>



            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary"
                onClick={() => props.onCancel()}>
                {t('cancel')}
              </button>
              <button type="button" className="standalone-button btn btn-primary"
                onClick={() => onSave()}>
                {t('save')}
              </button>
            </Modal.Footer>
          </Modal>)
}

const StandaloneSwitch = (props) => {
  return <Switch className="standalone-switch"
            onColor= "#00305f"
            height={20} width={48} handleDiameter={18}
            uncheckedIcon={false} checkedIcon={false} 
            onChange={(checked) => props.onChange(checked)} 
            checked={props.checked} />
}

export const DataHub = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({count: 0, results: []})
  const [loading, setLoading] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const targetUuid = useRef(null)
  const pageSizeRef = useRef(10);
  const offsetRef = useRef(0);
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [ companySiteList, setCompanySiteList ] = useState([]);
  const [ companyList, setCompanyList ] = useState([]);
  const [ filteringTarget, setFilteringTarget ] = useState(null);
  const [ showDataHubWithTODONotes, setShowDataHubWithTODONotes ] = useState(false);
  const [ showDataHubWithErrorSensors, setShowDataHubWithErrorSensors ] = useState(false);
  const user = useSelector(state => state.main.user)
  const parameters = useRef({})
  const { search } = useLocation();
  const navigate = useNavigate();

  const headers = [
    { key: 'name', title: t('name'), sort: SORT.DEFAULT },
    { key: 'company_site', title: t('company_site'), sort: SORT.DEFAULT },
    { key: 'mac_address', title: t('mac_address'), sort: SORT.NONE },
    { key: 'global_polling_rate_ms', title: t('global_polling_rate_ms'), sort: SORT.DEFAULT, className: 'text-center' },
    { key: 'is_online', title: t('is_online'), sort: SORT.DEFAULT, className: 'text-center',
      rendering: (uuid, item) => { 

        return <div className='text-center'> 
                  <FontAwesomeIcon icon={item?faToggleOn:faToggleOff} style={{ fontSize: '20px', color: item?'#1E8449':'#E74C3C' }} />
              </div>
      } 
    },
    { key: 'calc_sensors', title: t('calc_sensors'), sort: SORT.NONE, className: 'text-center',
      rendering: (uuid, item) => { 
        const dataItem = data.results.find( item => item.uuid === uuid )
        return <div className='text-center badge-container' onClick={() => navigate( routes.sensor.url(uuid))} role='button'>
                  <span className="badge bg-primary">{dataItem.calc_sensors_error} / {item} sensors</span>
                </div>
        } 
    },
    { key: 'calc_data_hub_notes', title: t('calc_data_hub_notes'), sort: SORT.NONE, className: 'text-center',
      rendering: (uuid, item) => { 
        const dataItem = data.results.find( item => item.uuid === uuid )
        return <div className='text-center' onClick={() => navigate( routes.sensor.url(uuid))} role='button'>
                  <span className="badge bg-success">{dataItem.calc_data_hub_notes_todo} / {item} notes</span>
                </div>
        } 
    },
  ]

  const refresh = async (limit, offset, parameters = {}) => {
    try{
      setLoading(true)
      const urlParams = new URLSearchParams(parameters).toString();
      const response = await axiosInstance.get(`/api/v1/data_hubs/?limit=${limit}&offset=${offset}&expand=company_site${urlParams.length>0?`&${urlParams}`:''}`);
      const resultsLocal = response.data.results.map( item => ({
        ...item, company_site: item.company_site.name
      }))
      setData({ count: response.data.count, results: resultsLocal })
      setLoading(false)
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  useEffect( () => {
    ( async () => {
      try{      
        axiosInstance.get(`/api/v1/company_sites/?expand=company`).then( (response) => {
          setCompanySiteList( response.data )
        })
        // axiosInstance.get(`/api/v1/companies/`).then( (response) => 
        //   setCompanyList( response.data )
        // )
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();
  }, [user, t])

  useEffect( () => {
    if( user ){      

      const urlSearchParams = new URLSearchParams(search);
      const company_site = urlSearchParams.get('company_site')
      if( company_site ){
        parameters.current = { company_site }
        setFilteringTarget(company_site)
      }

      refresh(pageSizeRef.current, offsetRef.current, parameters.current)
    }
  }, [user])

  // -------------------
  // Handlers
  // -------------------

  const onPageChange = async (pageSize, page) => {
    // Store parameters
    pageSizeRef.current = pageSize;
    offsetRef.current = pageSize*page;
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSortClick = async (key, direction, pageSize, page) => {
    const prefix = direction === SORT.DOWN?"-":""
    parameters.current = { ...parameters.current, ordering : `${prefix}${key}` }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSearch = (pattern, pageSize, page) => {
    parameters.current = { ...parameters.current, search : pattern }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onClickEdit = (uuid) => {
    targetUuid.current = uuid;
    setShowDetail(true)
  }

  const onClickDelete = (uuid) => {
    targetUuid.current = uuid;
    setShowConfirmation(true)
  }

  const onDeleteProceed = async () => {
    try{
      setShowConfirmation(false)
      await axiosInstance.delete(`/api/v1/data_hubs/${targetUuid.current}/`);

      // Refresh
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const onClickAdd = () => {
    targetUuid.current = null;
    setShowDetail(true)
  }

  const onSaveCompleted = () => {
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
    setShowDetail(false)
  }
  
  const FILTERING_TYPE = {
    COMPANY: 'COMPANY', COMPANY_SITE: 'COMPANY_SITE'
  }
  const onSelectCompanySiteFiltering = (selected) => {
    if( selected == null ){
      delete parameters.current["company_site"]
      delete parameters.current["company"]
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)
      setFilteringTarget(null)
      return
    }
    


    const urlParams = selected.type === FILTERING_TYPE.COMPANY?`company=${selected.value}`:`company_site=${selected.value}`
    const refreshParams = selected.type === FILTERING_TYPE.COMPANY?{company: selected.value}:{company_site: selected.value}

    if( selected.type === FILTERING_TYPE.COMPANY )
      delete parameters.current["company_site"]
    if( selected.type === FILTERING_TYPE.COMPANY_SITE )
      delete parameters.current["company"]

    // Proceed with creation
    window.history.replaceState(null, "Standalone UI", `/dashboard/datahub?${urlParams}`)
    parameters.current = { ...parameters.current, ...refreshParams  }
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
    setFilteringTarget(selected.value)
  }

  const onShowOnlyDataHubWithTODONotes = (checked) => {
    // Set new state
    setShowDataHubWithTODONotes(!showDataHubWithTODONotes)
    // Update parameters
    if( checked ) parameters.current = { ...parameters.current, calc_data_hub_notes_todo__gte: 1 }
    else delete parameters.current["calc_data_hub_notes_todo__gte"]
    // Request list
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onShowOnlyDataHubWithErrorSensors = (checked) => {
    // Set new state
    setShowDataHubWithErrorSensors(!showDataHubWithErrorSensors)
    // Update parameters
    if( checked ) parameters.current = { ...parameters.current, calc_sensors_error__gte: 1 }
    else delete parameters.current["calc_sensors_error__gte"]
    // Request list
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const companySiteNameList = companySiteList.map( item => ({value: item.uuid, type: FILTERING_TYPE.COMPANY_SITE, label: `${item.name} - ${item.company.name}`}) )
  const companyNameList = companyList.map( item => ({value: item.uuid, type: FILTERING_TYPE.COMPANY, label:item.name}) )
  let filteringList = [...companyNameList, ...companySiteNameList ]

  const compare = ( a, b ) => {
    if ( a.label < b.label )return -1;    
    if ( a.label > b.label ) return 1;    
    return 0;
  }  
  filteringList = filteringList.sort( compare );

  console.log("filteringList ", filteringList)

  return <div className='h-100'>
          <div className='d-flex justify-content-between w-100 align-items-start'>
            <div>
              <div className='d-flex align-items-center'>
                <label style={{ width: '300px'}}>{t('only_datahub_with_sensors_in_error')}</label>
                <StandaloneSwitch 
                  onChange={(checked) => onShowOnlyDataHubWithErrorSensors(checked)} 
                  checked={showDataHubWithErrorSensors} />             
              </div>
              <div className='d-flex align-items-center mt-2'>
                <label style={{ width: '300px'}}>{t('only_datahub_with_todo_notes')}</label>
                <StandaloneSwitch 
                  onChange={(checked) => onShowOnlyDataHubWithTODONotes(checked)} 
                  checked={showDataHubWithTODONotes} />
              </div>

            </div>
            <div className="form-group flex-grow-1 ps-5">

              <Select options={filteringList}
                      isClearable
                      onChange={(selected) => onSelectCompanySiteFiltering(selected) }
                      className="company-site-datahub"
                      classNamePrefix="company-site-datahub"
                      styles={{
                        menuPortal: styles => ({...styles, zIndex: 10000 })
                      }}
                      menuPortalTarget={document.body} 
                      value={filteringList.filter( item => item.value === filteringTarget)} />
            </div>
          </div>

          <Table data={data} headers={headers} loading={loading}
            onSortClick={ (key, direction, pageSize, page) => onSortClick(key, direction, pageSize, page)} 
            onClickRow={(id) => onClickEdit(id)} 
            onClickDelete={(id) => onClickDelete(id)} 
            onClickAdd={() => onClickAdd()} 
            onPageChange={(pageSize, page) => onPageChange(pageSize, page)}             
            onSearch={(pattern, pageSize, page) => onSearch(pattern, pageSize, page)} />


          <ModalConfirmation title={`${t('delete')} ${t('user')}`} 
            onCancel={() => setShowConfirmation(false)} 
            onProceed={() => onDeleteProceed()}
            show={showConfirmation} />

            
          <DataHubModal show={showDetail} 
            onCancel={() => setShowDetail(false)}
            onSave={() => onSaveCompleted()}
            targetUuid={targetUuid.current}
            />
        </div>    
  
}