import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from "react";
import { axiosInstance } from '../common/utils';
import './ModalSensorDataOutput.css'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify';


export const ModalSensorDataOutput = (props) => {
  const { t } = useTranslation();
  const [ sensorDataOutputList, setSensorDataOutputList ] = useState([]);
  const [ sensorDataOutput, setSensorDataOutput ] = useState({
    uuid: '', input_name: '', output_name: '', output_type: '', time_window_seconds: ''
  });
  const [ isModified, setIsModified ] = useState(false);
  const [ outputTypeList, setOutputTypeList ] = useState([])

  const refresh = async () => {
    const response = await axiosInstance.get(`/api/v1/sensors/${props.sensorUuid}/?expand=sensor_data_outputs`);
    let sensor_data_outputs = response.data.sensor_data_outputs
    sensor_data_outputs = sensor_data_outputs.map( item => ({...item, show_delete: false}))
    setSensorDataOutputList([...sensor_data_outputs])
    if( response.data.sensor_data_outputs.length > 0)
      setSensorDataOutput(response.data.sensor_data_outputs[0])
  }

  useEffect( () => {
    ( async () => {
      const response = await axiosInstance.get(`/api/v1/sensor_data_outputs/output_type/`);
      setOutputTypeList([...response.data])
    })();
  }, [])

  useEffect(() => {
    if( props.sensorUuid == null) return
    refresh()
  }, [props.sensorUuid])

  const onChangeValue = (e, key) => {
    setSensorDataOutput({...sensorDataOutput, [key]: e.target.value})
    setIsModified(true)
  }

  const onClickSensorDataOutput = (item) => {
    setSensorDataOutput(item)
    setIsModified(false)

  }

  const onMouseEnter = (targetItem) => {
    const sensorDataOutputListLocal = [...sensorDataOutputList]
    const senseDataOutput = sensorDataOutputListLocal.find( item => item.uuid === targetItem.uuid)
    senseDataOutput.show_delete = true
    setSensorDataOutputList(sensorDataOutputListLocal)
  }

  const onMouseLeave = (targetItem) => {
    const sensorDataOutputListLocal = [...sensorDataOutputList]
    const senseDataOutput = sensorDataOutputListLocal.find( item => item.uuid === targetItem.uuid)
    senseDataOutput.show_delete = false
    setSensorDataOutputList(sensorDataOutputListLocal)
  }

  const onClickDelete = async (e, item) => {
    e.stopPropagation()
    console.log("onclickdelete ", item)
    try{
      console.log("item")
      await axiosInstance.delete(`/api/v1/sensor_data_outputs/${item.uuid}/`);

      // Refresh
      refresh()
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }

  }

  const onSave = async () =>{
    try{
      await axiosInstance.put(`/api/v1/sensor_data_outputs/${sensorDataOutput.uuid}/`, {...sensorDataOutput});

      // Refresh
      refresh()
      toast.success(t('request_succeded'))
      setIsModified(false)
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const onClickAdd = async () => {
    try{
      const output_type_default = outputTypeList[0]
      const body = {
        output_name: `output-${new Date().getTime()}`,
        input_name: `input-${new Date().getTime()}`,
        sensor: props.sensorUuid,
        output_type: output_type_default
      }
      await axiosInstance.post(`/api/v1/sensor_data_outputs/`, {...body});

      // Refresh
      refresh()
      toast.success(t('request_succeded'))
      setIsModified(false)
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  return (<Modal show={props.show} onHide={() => props.onCancel()} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex">
            <div className="w-33 d-flex flex-column ">
              <div className="flex-grow-1 standalone-table-container" style={{ height: '0px', overflow:'auto', minHeight: 'initial'}}>
              {sensorDataOutputList.map( (item, idx) => 
                <div key={idx} className="p-1 pe-3">
                  <div className={`standalone-chip p-2 d-flex justify-content-between align-items-center ${sensorDataOutput.uuid===item.uuid?'standalone-chip-selected':''}`}
                  onMouseEnter={() => onMouseEnter(item)}
                  onMouseLeave={() => onMouseLeave(item)}
                  onClick={() => onClickSensorDataOutput(item)}>
                  <div>{item.output_name}</div>
                  {item.show_delete?<FontAwesomeIcon icon={faTimes} style={{ fontSize: '20px'}} 
                    onClick={(e) => onClickDelete(e, item)}/>:null}
                  </div>
                </div>)}
              </div>
              <div className="text-end pe-3 mt-2">
                <button type="button" className="standalone-button btn btn-primary"
                  onClick={() => onClickAdd()}>
                  {t('add')}
                </button>
              </div>
            </div>
            <div className="w-66 border-start ps-3">
              <div className="form-group">
                <label>{t('output_name')}</label>
                <input type="text" className="form-control mt-2" 
                  value={sensorDataOutput.output_name} 
                  onChange={(e) => onChangeValue(e, 'output_name')} />
              </div>
              <div className="form-group mt-2">
                <label>{t('input_name')}</label>
                <input type="text" className="form-control mt-2" 
                  value={sensorDataOutput.input_name} onChange={(e) => onChangeValue(e, 'input_name')} />
              </div>
              <div className="form-group mt-2">
                <label>{t('output_type')}</label>
                <select type="text" className="form-control mt-2" 
                  value={sensorDataOutput.output_type} onChange={(e) => onChangeValue(e, 'output_type')} >
                    {outputTypeList.map( (item, idx) => <option key={idx}>{item}</option>)}
                </select>
              </div>
              <div className="form-group mt-2">
                <label>{t('time_window_seconds')}</label>
                <input type="number" className="form-control mt-2" 
                  value={sensorDataOutput.time_window_seconds} onChange={(e) => onChangeValue(e, 'time_window_seconds')} />
              </div>
              <div className="mt-2 text-end w-100">
                <button type="button" disabled={isModified===false} className="standalone-button btn btn-primary"
                  onClick={() => onSave()}>
                  {t('save')}
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary"
              onClick={() => props.onCancel()}>
              {t('cancel')}
            </button>
            <button type="button" className="standalone-button btn btn-primary"
              onClick={() => props.onProceed()}>
              {t('ok')}
            </button>
          </Modal.Footer>
        </Modal>)
}