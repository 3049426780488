import { Dashboard } from '../components/Dashboard'
import { useTranslation } from "react-i18next";
import { Table, SORT } from '../components/Table'
import { useEffect, useRef, useState } from 'react';
import { axiosInstance } from '../common/utils';
import Modal from 'react-bootstrap/Modal';
import { ModalConfirmation } from '../components/ModalConfirmation';
import { toast } from 'react-toastify';
import {useSelector, useDispatch} from 'react-redux'


const UserModal = (props) => {
  const { t } = useTranslation();
  const [ user, setUser ] = useState({
    first_name: '', last_name: '', username: '', email: ''
  });

  const onSave = async () => {
    try{
      // Create
      if( props.targetUuid == null){
        await axiosInstance.post(`/api/v1/users/`, {...user});
      }

      // Update
      if( props.targetUuid ){
        await axiosInstance.put(`/api/v1/users/${props.targetUuid}/`, {...user});
      }

      // Call parent
      props.onSave();
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))
    }
  }

  useEffect( () => {
    ( async () => {
      try{
        if( props.targetUuid == null){
          setUser({
            first_name: '', last_name: '', username: '', email: ''
          })
          return
        }

        if( props.targetUuid){
          const response = await axiosInstance.get(`/api/v1/users/${props.targetUuid}/`);
          setUser({...response.data})
          return
        }
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();

  }, [props.show, props.targetUuid, t])

  const operation = props.targetUuid?t('edit'):t('add')
  return (<Modal show={props.show} onHide={() => props.onCancel()} centered>
            <Modal.Header closeButton>
              <Modal.Title>{operation} {t('user')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>{t('first_name')}</label>
                <input type="text" className="form-control mt-2" 
                  value={user.first_name} onChange={(e) => setUser({...user, first_name: e.target.value})} />
              </div>
              <div className="form-group mt-2">
                <label>{t('last_name')}</label>
                <input type="text" className="form-control mt-2"  
                  value={user.last_name} onChange={(e) => setUser({...user, last_name: e.target.value})} />
              </div>
              <div className="form-group mt-2">
                <label>{t('username')}</label>
                <input type="text" className="form-control mt-2"  
                  value={user.username} onChange={(e) => setUser({...user, username: e.target.value})} />
              </div>
              <div className="form-group mt-2">
                <label>Email</label>
                <input type="email" className="form-control mt-2"  
                  value={user.email} onChange={(e) => setUser({...user, email: e.target.value})} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary"
                onClick={() => props.onCancel()}>
                {t('cancel')}
              </button>
              <button type="button" className="standalone-button btn btn-primary"
                onClick={() => onSave()}>
                {t('save')}
              </button>
            </Modal.Footer>
          </Modal>)
}

export const User = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({count: 0, results: []})
  const [loading, setLoading] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const targetUuid = useRef(null)
  const pageSizeRef = useRef(10);
  const offsetRef = useRef(0);
  const [showConfirmation, setShowConfirmation] = useState(false)
  const user = useSelector(state => state.main.user)
  const parameters = useRef({})

  const headers = [
    { key: 'first_name', title: t('first_name'), sort: SORT.DEFAULT },
    { key: 'last_name', title: t('last_name'), sort: SORT.DEFAULT },
    { key: 'username', title: t('username'), sort: SORT.DEFAULT },
    { key: 'email', title: t('email'), sort: SORT.DEFAULT },
  ]

  const refresh = async (limit, offset, parameters = {}) => {
    try{
      setLoading(true)
      const urlParams = new URLSearchParams(parameters).toString();
      const response = await axiosInstance.get(`/api/v1/users/?limit=${limit}&offset=${offset}${urlParams.length>0?`&${urlParams}`:''}`);
      setData(response.data)
      setLoading(false)
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  useEffect( () => {
    if( user )
      refresh(pageSizeRef.current, offsetRef.current)
  }, [user])

  // -------------------
  // Handlers
  // -------------------

  const onPageChange = async (pageSize, page) => {
    // Store parameters
    pageSizeRef.current = pageSize;
    offsetRef.current = pageSize*page;
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSortClick = async (key, direction, pageSize, page) => {
    const prefix = direction === SORT.DOWN?"-":""
    parameters.current = { ...parameters.current, ordering : `${prefix}${key}` }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSearch = (pattern, pageSize, page) => {
    parameters.current = { ...parameters.current, search : pattern }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onClickEdit = (uuid) => {
    targetUuid.current = uuid;
    setShowDetail(true)
  }

  const onClickDelete = (uuid) => {
    targetUuid.current = uuid;
    setShowConfirmation(true)
  }

  const onDeleteProceed = async () => {
    try{
      setShowConfirmation(false)
      await axiosInstance.delete(`/api/v1/users/${targetUuid.current}/`);

      // Refresh
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const onClickAdd = () => {
    targetUuid.current = null;
    setShowDetail(true)
  }

  const onSaveCompleted = () => {
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
    setShowDetail(false)
  }



  return <div className='h-100'>
          <Table data={data} headers={headers} loading={loading}
            onSortClick={ (key, direction, pageSize) => onSortClick(key, direction, pageSize)} 
            onClickRow={(id) => onClickEdit(id)} 
            onClickDelete={(id) => onClickDelete(id)} 
            onClickAdd={() => onClickAdd()} 
            onPageChange={(pageSize, page) => onPageChange(pageSize, page)} 
            onSearch={(pattern, pageSize, page) => onSearch(pattern, pageSize, page)} />

          <ModalConfirmation title={`${t('delete')} ${t('user')}`} 
            onCancel={() => setShowConfirmation(false)} 
            onProceed={() => onDeleteProceed()}
            show={showConfirmation} />

            
          <UserModal show={showDetail} 
            onCancel={() => setShowDetail(false)}
            onSave={() => onSaveCompleted()}
            targetUuid={targetUuid.current}
            />
        </div>    
  
}