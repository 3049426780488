
import { Dashboard } from '../components/Dashboard'
import { useTranslation } from "react-i18next";
import { Table, SORT } from '../components/Table'
import { useEffect, useState, useRef } from 'react';
import { axiosInstance } from '../common/utils';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { ModalConfirmation } from '../components/ModalConfirmation';
import { ModalWarning } from '../components/ModalWarning';
import {useSelector, useDispatch} from 'react-redux'
import { toast } from 'react-toastify';
import routes from '../common/routes'
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faCheck, faTimes, faRotateLeft } from '@fortawesome/free-solid-svg-icons'

const InfluxDBCredentialModal = (props) => {
  const { t } = useTranslation();
  const [ influxDBCredential, setInfluxDBCredential ] = useState({
    name: '', server: '', token: ''
  });
  const [ serverList, setServerList ] = useState([])
  const user = useSelector(state => state.main.user)



  const onSave = async () => {
    try{
      // Create
      if( props.targetUuid == null){
        await axiosInstance.post(`/api/v1/influxdb_credentials/`, {...influxDBCredential});
      }

      // Update
      if( props.targetUuid ){
        await axiosInstance.put(`/api/v1/influxdb_credentials/${props.targetUuid}/`, {...influxDBCredential});
      }

      // Call parent
      props.onSave();
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.success(t('request_failed'))   
    }
  }

  useEffect( () => {
    ( async () => {
      try{
        if( props.targetUuid == null){
          setInfluxDBCredential({ name: '', server: '' })
          return
        }


        if( props.targetUuid){
          const response = await axiosInstance.get(`/api/v1/influxdb_credentials/${props.targetUuid}/`);
          setInfluxDBCredential({...response.data})
          return
        }
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();

  }, [props.show, props.targetUuid, t])

  useEffect( () => {
    ( async () => {
      try{
        const response = await axiosInstance.get(`/api/v1/influxdb_servers/`);
        console.log("response.data ", response.data)
        setServerList( response.data )
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();
  }, [user, t])

  
  const serverNameList = serverList.map( item => ({value: item.uuid, label:item.name}) )
  const operation = props.targetUuid?t('edit'):t('add')
  return (<Modal show={props.show} onHide={() => props.onCancel()} centered>
            <Modal.Header closeButton>
              <Modal.Title>{operation} {t('influxdb_credential')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>{t('name')}</label>
                <input type="text" className="form-control mt-2" 
                  value={influxDBCredential.name} onChange={(e) => setInfluxDBCredential({...influxDBCredential, name: e.target.value})} />
              </div>
              <div className="form-group mt-2">
                <label>{t('server')}</label>
                <Select options={serverNameList} 
                        onChange={(selected) => setInfluxDBCredential({...influxDBCredential, server: selected.value}) }
                        className="company-site"
                        classNamePrefix="company-site"
                        styles={{
                          menuPortal: styles => ({...styles, zIndex: 10000 })
                        }}
                        menuPortalTarget={document.body} 
                        value={serverNameList.filter( item => item.value === influxDBCredential.server)} />
              </div>
              <div className="form-group mt-2">
                <label>{t('token')}</label>
                <input type="text" className="form-control mt-2" 
                  value={influxDBCredential.token} onChange={(e) => setInfluxDBCredential({...influxDBCredential, token: e.target.value})} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary"
                onClick={() => props.onCancel()}>
                {t('cancel')}
              </button>
              <button type="button" className="standalone-button btn btn-primary"
                onClick={() => onSave()}>
                {t('save')}
              </button>
            </Modal.Footer>
          </Modal>)
}

export const InfluxDBCredential = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({ count: 0, results: []})
  const [loading, setLoading] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const targetUuid = useRef(null)
  const pageSizeRef = useRef(10);
  const offsetRef = useRef(0);
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const user = useSelector(state => state.main.user)
  const navigate = useNavigate();
  const { influxdb_server } = useParams();
  const parameters = useRef({ server: influxdb_server })
  const [searchParams] = useSearchParams();

  const headers = [
    { key: 'name', title: t('name'), sort: SORT.DEFAULT },
    { key: 'server', title: t('server'), sort: SORT.DEFAULT },
    { key: 'calc_data_hubs', title: t('calc_data_hubs'), sort: SORT.DEFAULT, className: 'text-center',
    rendering: (uuid, item) => { 
      return <div className='text-center'>
              <span className="badge bg-secondary" 
              // onClick={() => navigate( routes.data_hub.url + `?data_hub=${uuid}`)}
              >
              {item} data_hub
              </span>
            </div>
      }
    },
    { key: 'validated', title: t('validated'), sort: SORT.NONE, className: 'text-center',
    rendering: (uuid, item) => {
      return <div className='text-center'>
                {item == null && <FontAwesomeIcon className="ms-2" icon={faQuestion} />}
                {item === "KO" && <FontAwesomeIcon className="ms-2" icon={faTimes} />}
                {item === "OK" && <FontAwesomeIcon className="ms-2" icon={faCheck} />}
                {item === "LOADING" && <div className="spinner-border" role="status" style={{ width: '15px', height: '15px'}}></div>}
              </div>
    }
  },
  ]

  const refresh = async (limit, offset, parameters = {}) => {
    try{
      setLoading(true)
      const urlParams = new URLSearchParams(parameters).toString();
      const response = await axiosInstance.get(`/api/v1/influxdb_credentials/?limit=${limit}&offset=${offset}&expand=server${urlParams.length>0?`&${urlParams}`:''}`);
      const resultsLocal = response.data.results.map( item => ({
        ...item, server: item.server.name, 
        calc_data_hubs: item.calc_data_hubs_as_data + item.calc_data_hubs_as_health_info,
        validated: null, 
      }))
      setData({ count: response.data.count, results: resultsLocal })
      setLoading(false)
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }


  useEffect( () => {
    if(user)
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }, [user]);

  useEffect( () => {
    if( searchParams.get('uuid') == null ) return
    // Show selected server
    targetUuid.current = searchParams.get('uuid')
    setShowDetail(true)
  }, [searchParams])

  // -------------------
  // Handlers
  // -------------------

  const onPageChange = async (pageSize, page) => {
    // Store parameters
    pageSizeRef.current = pageSize;
    offsetRef.current = pageSize*page;
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }


  const onSortClick = async (key, direction, pageSize, page) => {
    const prefix = direction === SORT.DOWN?"-":""
    let keyLocal = key === "server"?"server__name":key
    parameters.current = { ...parameters.current, ordering : `${prefix}${keyLocal}` }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSearch = (pattern, pageSize, page) => {
    parameters.current = { ...parameters.current, search : pattern }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }


  const onClickEdit = async (uuid, header) => {
    if( header.key === 'validated'){
      // Extract datalocal
      let dataLocal = JSON.parse(JSON.stringify(data))
      let item = dataLocal.results.find( item => item.uuid === uuid);
      item.validated = "LOADING"
      setData(dataLocal)


      dataLocal = JSON.parse(JSON.stringify(data))
      item = dataLocal.results.find( item => item.uuid === uuid);
      // Validate credentials
      try{
        await axiosInstance.post(`/api/v1/influxdb_credentials/${item.uuid}/validate/`)
        item.validated = "OK"
      }catch(e){
        console.log("e ", e)
        item.validated = "KO"
      }
      setData(dataLocal)
    }else{
      targetUuid.current = uuid;
      setShowDetail(true)
    }

  }

  const onClickDelete = (uuid) => {
    const obj = data.results.find( item => item.uuid === uuid );
    if(obj.calc_data_hubs > 0 ){
      setShowWarning(true)
      return
    }

    targetUuid.current = uuid;
    setShowConfirmation(true)
  }

  const onDeleteProceed = async () => {
    try{
      setShowConfirmation(false)

      await axiosInstance.delete(`/api/v1/influxdb_credentials/${targetUuid.current}/`);

      // Refresh
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)

      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const onClickAdd = () => {
    targetUuid.current = null;
    setShowDetail(true)
  }
  
  const onSaveCompleted = () => {
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
    setShowDetail(false)
  }

  return <div className='h-100'>
          <div className='d-flex w-100' role="button">
            <div className="h-100" onClick={() => navigate(routes.influxdb_server.url)}>
              <FontAwesomeIcon role="button" icon={faRotateLeft} />
              <label role="button"  className="ms-2">{t('back')}</label>
            </div>
          </div>
          <Table data={data} headers={headers} 
            onSortClick={ (key, direction, pageSize) => onSortClick(key, direction, pageSize)} 
            onClickRow={(id, header) => onClickEdit(id, header)} 
            onClickDelete={(id) => onClickDelete(id)} 
            onClickAdd={() => onClickAdd()} 
            onPageChange={(pageSize, page) => onPageChange(pageSize, page)} 
            loading={loading} 
            onSearch={(pattern, pageSize, page) => onSearch(pattern, pageSize, page)} />

          <ModalConfirmation title={`${t('delete')} ${t('influxdb_credential')}`} 
            onCancel={() => setShowConfirmation(false)} 
            onProceed={() => onDeleteProceed()}
            show={showConfirmation} />

          <ModalWarning title={t('warning')}
            message={t('dependent_data_hubs')}
            show={showWarning} 
            onProceed={() => setShowWarning(false)}
            />

          <InfluxDBCredentialModal show={showDetail} 
            onCancel={() => setShowDetail(false)}
            onSave={() => onSaveCompleted()}
            targetUuid={targetUuid.current}
            />

        </div>    
  
}
