import { useTranslation } from "react-i18next";
import { Table, SORT } from '../components/Table'
import { useEffect, useRef, useState } from 'react';
import { axiosInstance } from '../common/utils';
import Modal from 'react-bootstrap/Modal';
import { ModalConfirmation } from '../components/ModalConfirmation';
import { ModalSensorDataOutput } from '../components/ModalSensorDataOutput';
import { toast } from 'react-toastify';
import {useSelector } from 'react-redux'
import Select from 'react-select';
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import routes from '../common/routes'
import { DataHubControlPanel } from "../components/DataHubControlPanel";


const SensorModal = (props) => {
  const { t } = useTranslation();
  const [ sensor, setSensor ] = useState({
    name: '', 
    data_hub: '',
    config: '', 
    configStr: null,
    sensor_type: ''
  });
  // const [ dataHubList, setDataHubList ] = useState([])
  const [ sensorTypeList, setSensorTypeList ] = useState([])
  const [ configValid, setConfigValid ] = useState(true)
  const user = useSelector(state => state.main.user)

  const onSave = async () => {
    try{
      // Create
      if( props.targetUuid == null){
        await axiosInstance.post(`/api/v1/sensors/`, {...sensor});
      }

      // Update
      if( props.targetUuid ){
        await axiosInstance.put(`/api/v1/sensors/${props.targetUuid}/`, {...sensor});
      }

      // Call parent
      props.onSave();
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))
    }
  }

  useEffect( () => {
    ( async () => {
      try{
        if( props.targetUuid == null){
          const sensor_type_default = sensorTypeList[0]
          setSensor({
            name: '', 
            data_hub: props.dataHub,
            config: {}, 
            configStr: null,
            sensor_type: sensor_type_default
          })
          return
        }

        if( props.targetUuid ){
          const response = await axiosInstance.get(`/api/v1/sensors/${props.targetUuid}/`);
          const obj = {...response.data}
          setSensor({...obj})
          return
        }
      }catch(e){   
        console.log("errror ", e)
        toast.error(t('request_failed'))   
      }
    })();

  }, [props.show, props.targetUuid, t])

  useEffect( () => {
    ( async () => {
      try{      
        // let response = await axiosInstance.get(`/api/v1/data_hubs/`);
        // setDataHubList( response.data )

        const response = await axiosInstance.get(`/api/v1/sensors/type/`);
        setSensorTypeList([...response.data])
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();
  }, [user, t])



  const onConfigurationChange = (e) => {
    const { value } = e.target
    try{
      const configLocal = JSON.parse(value)
      console.log("configLocal ", configLocal)
      setSensor({...sensor, config: configLocal, configStr: null})
      setConfigValid(true)
    }catch(e){
      console.error(`Value ${value} is not json formatted`)
      setSensor({...sensor, configStr: value})
      setConfigValid(false)
    }
    
  }

  const getConfiguration = () => {
    if( sensor.configStr ){
      return sensor.configStr;
    }else{
      return JSON.stringify(sensor.config, null, 4)
    }
  }

  // const dataHubNameList = dataHubList.map( item => ({value: item.uuid,label:item.name}) )
  const operation = props.targetUuid?t('edit'):t('add')
  return (<Modal show={props.show} onHide={() => props.onCancel()} centered >
            <Modal.Header closeButton>
              <Modal.Title>{operation} {t('sensor')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '500px' }} className='standalone-table-container'>

              <div className="form-group w-100">
                <label>{t('name')}</label>
                <input type="text" className="form-control mt-2" 
                  value={sensor.name} onChange={(e) => setSensor({...sensor, name: e.target.value})} />
              </div>

              <div className="form-group w-100 mt-3">
                <label>{t('sensor_type')}</label>
                <select className='form-control' value={sensor.sensor_type} 
                  onChange={(e) => setSensor({...sensor, sensor_type: e.target.value})}>
                  {sensorTypeList.map( (item, idx) => 
                    <option key={idx}>{item}</option>
                  )}                  
                </select>
              </div>

              {/* <div className="form-group w-100 mt-3">
                <label>{t('data_hub')}</label>
                <Select options={dataHubNameList} 
                        onChange={(selected) => setSensor({...sensor, data_hub: selected.value}) }
                        className="company-site"
                        classNamePrefix="company-site"
                        styles={{
                          menuPortal: styles => ({...styles, zIndex: 10000 })
                        }}
                        menuPortalTarget={document.body} 
                        value={dataHubNameList.filter( item => item.value === sensor.data_hub)} />                  
              </div> */}

              <div className="form-group w-100 mt-3">
                <label>{t('config')}</label>
                <textarea className={`form-control ${configValid?'':'is-invalid'}`}
                  value={getConfiguration()} style={{ fontFamily: 'Consolas', height: '200px'}} 
                  onChange={(e) => onConfigurationChange(e)}/>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary"
                onClick={() => props.onCancel()}>
                {t('cancel')}
              </button>
              <button type="button" className="standalone-button btn btn-primary" 
                disabled={configValid===false} onClick={() => onSave()}>
                {t('save')}
              </button>
            </Modal.Footer>
          </Modal>)
}



export const Sensor = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({count: 0, results: []})
  const [loading, setLoading] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const targetUuid = useRef(null)
  const pageSizeRef = useRef(10);
  const offsetRef = useRef(0);
  const [showConfirmation, setShowConfirmation] = useState(false)
  // const [ dataHubList, setDataHubList ] = useState([]);
  // const [ dataHubTarget, setDataHubTarget ] = useState(null);
  // const { search } = useLocation();
  const user = useSelector(state => state.main.user)
  const { data_hub } = useParams()
  const parameters = useRef({ data_hub: data_hub})
  const [ dataHub, setDataHub ] = useState({
    name: '', 
    company_site: {name:'', company:{name:''}},
    influxdb_for_health_info: '', influxdb_for_health_info_bucket: '', influxdb_for_health_info_org: '',
    influxdb_for_data: '', influxdb_for_data_bucket: '', influxdb_for_data_org: '',
    global_polling_rate_ms: '',
    is_online: false,
    mac_address: '',
    mac_address_array: [],
  })


  const [showSensorDataOutput, setShowSensorDataOutput ] = useState(false)
  const navigate = useNavigate();
  const headers = [
    { key: 'name', title: t('name'), sort: SORT.DEFAULT },
    { key: 'sensor_type', title: t('sensor_type'), sort: SORT.DEFAULT, className: 'text-center' },
    { key: 'data_hub', title: t('data_hub'), sort: SORT.DEFAULT, className: 'text-center' },
    { key: 'status', title: t('status'), sort: SORT.DEFAULT, className: 'text-center',
    rendering: (uuid, item) => {
      let className = ''
      if( item === 'pending' ) className = 'bg-primary'
      if( item === 'configured' ) className = 'bg-success'
      if( item === 'error' ) className = 'bg-danger'
      return <div className='w-100 text-center'>
                <span className={`badge ${className}`}>{item.toUpperCase()}</span>
              </div>
      }
    },
    { key: 'last_data_collection', title: t('last_data_collection'), sort: SORT.DEFAULT, className: 'text-center',
    rendering: (uuid, item) => {      
      return <div className='w-100 text-center'>
                <span>{moment(item).format('DD/MM/YYYY HH:mm')}</span>
              </div>
      }
    },
    { key: 'calc_sensor_data_outputs', title: t('calc_sensor_data_outputs'), sort: SORT.DEFAULT, className: 'text-center',
    rendering: (uuid, item) => {
      return <div className='w-100 text-center' onClick={(e) => onClickSensorDataOutput(e, uuid)}>
                <span className="badge bg-primary">{item} outputs</span>
              </div>
    }},
  ]

  const onClickSensorDataOutput = (e, uuid) => {
    e.stopPropagation()
    targetUuid.current = uuid;
    setShowSensorDataOutput(true);
  }

  const refresh = async (limit, offset, parameters = {}) => {
    try{
      setLoading(true)
      const urlParams = new URLSearchParams(parameters).toString();
      const response = await axiosInstance.get(`/api/v1/sensors/?limit=${limit}&offset=${offset}&expand=data_hub${urlParams.length>0?`&${urlParams}`:''}`);
      const resultsLocal = response.data.results.map( item => ({
        ...item, data_hub: item.data_hub.name
      }))
      setData({ count: response.data.count, results: resultsLocal })
      setLoading(false)
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const getDataHub = async (uuid) => {
    try{
      const response = await axiosInstance(`/api/v1/data_hubs/${uuid}/?expand=company_site,company_site.company`)
      setDataHub({...response.data})
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  // useEffect( () => {
  //   ( async () => {
  //     try{      
  //       let response = await axiosInstance.get(`/api/v1/data_hubs/`);
  //       setDataHubList( response.data )
  //     }catch(e){   
  //       toast.error(t('request_failed'))   
  //     }
  //   })();
  // }, [user, t])

  useEffect( () => {
    if( user ){
      // const urlSearchParams = new URLSearchParams(search);
      // const data_hub = urlSearchParams.get('data_hub')
      // if( data_hub ){
      //   parameters.current = { data_hub }
      //   setDataHubTarget(data_hub)
      // }
      parameters.current = { data_hub }
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)

      // setDataHubTarget(data_hub)
      // Retrieve information from DataHub
      getDataHub(data_hub)

    }
  }, [user])


  // -------------------
  // Handlers
  // -------------------

  const onPageChange = async (pageSize, page) => {
    // Store parameters
    pageSizeRef.current = pageSize;
    offsetRef.current = pageSize*page;
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSortClick = async (key, direction, pageSize, page) => {
    const prefix = direction === SORT.DOWN?"-":""
    const keyLocal = key === "data_hub"?"data_hub__name":key
    parameters.current = { ...parameters.current, ordering : `${prefix}${keyLocal}` }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSearch = (pattern, pageSize, page) => {
    parameters.current = { ...parameters.current, search : pattern }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onClickEdit = (uuid) => {
    targetUuid.current = uuid;
    setShowDetail(true)
  }

  const onClickDelete = (uuid) => {
    targetUuid.current = uuid;
    setShowConfirmation(true)
  }

  const onDeleteProceed = async () => {
    try{
      setShowConfirmation(false)
      await axiosInstance.delete(`/api/v1/sensors/${targetUuid.current}/`);

      // Refresh
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const onClickAdd = () => {
    targetUuid.current = null;
    setShowDetail(true)
  }

  const onSaveCompleted = () => {
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
    setShowDetail(false)
  }
  
  // const onSelectCompanySiteFiltering = (selected) => {
  //   if( selected == null ){
  //     delete parameters.current["data_hub"]
  //     refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  //     setDataHubTarget(null)
  //     return
  //   }
    
  //   // Proceed with creation
  //   window.history.replaceState(null, "Standalone UI", `/dashboard/sensor?data_hub=${selected.value}`)
  //   parameters.current = { ...parameters.current, data_hub: selected.value  }
  //   refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  //   setDataHubTarget(selected.value)
  // }

  const onSensorDataOutputCompleted = () => {
    setShowSensorDataOutput(false)
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  // const dataHubNameList = dataHubList.map( item => ({value: item.uuid, label:item.name}) )
  return <div className='h-100 pe-3'>
          <div className='d-flex w-100 align-items-center justify-content-center' style={{ position: 'relative'}}>
            <div className="h-100" onClick={() => navigate(routes.data_hub.url)} style={{ position: 'absolute', top:"0", left: "0"}}>
              <FontAwesomeIcon role="button" icon={faRotateLeft} />
              <label role="button"  className="ms-2">{t('back')}</label>
            </div>
            {/* <div className="form-group"  style={{ width: "300px"}}>
                <Select options={dataHubNameList}
                        isClearable
                        onChange={(selected) => onSelectCompanySiteFiltering(selected) }
                        className="company-site-datahub"
                        classNamePrefix="company-site-datahub"
                        styles={{
                          menuPortal: styles => ({...styles, zIndex: 10000 })
                        }}
                        menuPortalTarget={document.body} 
                        value={dataHubNameList.filter( item => item.value === dataHubTarget)} />
            </div> */}
            <DataHubControlPanel dataHub={dataHub} />
          </div>

          <Table data={data} headers={headers} loading={loading}
            onSortClick={ (key, direction, pageSize, page) => onSortClick(key, direction, pageSize, page)} 
            onClickRow={(id) => onClickEdit(id)} 
            onClickDelete={(id) => onClickDelete(id)} 
            onClickAdd={() => onClickAdd()} 
            onPageChange={(pageSize, page) => onPageChange(pageSize, page)}             
            onSearch={(pattern, pageSize, page) => onSearch(pattern, pageSize, page)} />


          <ModalConfirmation title={`${t('delete')} ${t('user')}`} 
            onCancel={() => setShowConfirmation(false)} 
            onProceed={() => onDeleteProceed()}
            show={showConfirmation} />

            
          <SensorModal show={showDetail} 
            onCancel={() => setShowDetail(false)}
            onSave={() => onSaveCompleted()}
            dataHub={data_hub}
            targetUuid={targetUuid.current}
            />

          <ModalSensorDataOutput 
            title={t('calc_sensor_data_outputs')}
            show={showSensorDataOutput} 
            onCancel={() => setShowSensorDataOutput(false)}
            onProceed={() => onSensorDataOutputCompleted() }
            
            sensorUuid={targetUuid.current}
            />
        </div>    
  
}