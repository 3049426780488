import { createSlice } from '@reduxjs/toolkit'

const mainReducer = createSlice({
  name: 'main',
  initialState: {
    page: null, user: null
  },
  reducers: {
    setUser(state, action){
      console.log("setUser", action, state.page)
      state.user = action.payload
    },
    setPage(state, action){
      console.log("setPage", action, state.page)
      state.page = action.payload
    },
    todoAdded(state, action) {
      console.log("thank you", action, state)
      // state.push({
      //   id: action.payload.id,
      //   text: action.payload.text,
      //   completed: false
      // })
    },
    todoToggled(state, action) {
      const todo = state.find(todo => todo.id === action.payload)
      todo.completed = !todo.completed
    }
  }
})

export const { todoAdded, todoToggled, setPage, setUser } = mainReducer.actions
export default mainReducer.reducer