
import { Dashboard } from '../components/Dashboard'
import { useTranslation } from "react-i18next";
import { Table, SORT } from '../components/Table'
import { useEffect, useState, useRef } from 'react';
import { axiosInstance } from '../common/utils';
import {useSelector, useDispatch} from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import { ModalConfirmation } from '../components/ModalConfirmation';
import { ModalWarning } from '../components/ModalWarning';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom"
import routes from '../common/routes'
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format'
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'


momentDurationFormatSetup(moment);

const ScrapperModal = (props) => {
  const { t } = useTranslation();
  const [ scrapper, setScrapper ] = useState({
    scrapper_type: '',
    period_seconds: 0,
    influxdb_credentials: null,
    configuration: { source_url: '', source_token: ''}
  });
  const [scrapperTypeList, setScrapperTypeList] = useState([]);
  const [ influxDBCredentialList, setInfluxDBCredentialList ] = useState([]);

  const onSave = async () => {
    try{
      // Create
      if( props.targetUuid == null){
        await axiosInstance.post(`/api/v1/scrappers/`, {...scrapper});
      }

      // Update
      if( props.targetUuid ){
        await axiosInstance.put(`/api/v1/scrappers/${props.targetUuid}/`, {...scrapper});
      }

      // Call parent
      props.onSave();
      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  useEffect( () => {
    ( async () => {
      try{
        if( props.targetUuid == null){
          setScrapper({ 
            scrapper_type: scrapperTypeList[0],
            period_seconds: 0,
            influxdb_credentials: null,
            configuration: { 
              source_url: '', source_token: '',
              influxdb_org: '', influxdb_bucket: ''
            }
          })
          return
        }

        if( props.targetUuid){
          const response = await axiosInstance.get(`/api/v1/scrappers/${props.targetUuid}/`);
          setScrapper({...response.data})
          return
        }
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();

  }, [props.show, props.targetUuid, t])

  useEffect( () => {
    ( async () => {
      try{
        let response = await axiosInstance.get(`/api/v1/scrappers/type/`);
        setScrapperTypeList([...response.data])
        setScrapper({ scrapper_type: response.data[0], ...scrapper})

        response = await axiosInstance.get(`/api/v1/influxdb_credentials/?expand=server`);
        setInfluxDBCredentialList( response.data )
      }catch(e){   
        toast.error(t('request_failed'))   
      }
    })();
  }, [])

  const influxDBCredentialNameList = influxDBCredentialList.map( item => ({value: item.uuid,label: `Name: ${item.name} - Server: ${item.server.name}`}) )
  const operation = props.targetUuid?t('edit'):t('add')
  return (<Modal show={props.show} onHide={() => props.onCancel()} centered>
            <Modal.Header closeButton>
              <Modal.Title>{operation} {t('scrapper')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>{t('scrapper_type')}</label>
                <select className="form-control" value={scrapper.scrapper_type}
                  onChange={e => setScrapper({ ...scrapper, scrapper_type: e.target.value})}>
                  {scrapperTypeList.map( (item, idx) => 
                    <option key={idx}>{item}</option>
                  )}
                </select>                  
              </div>
              <div className="form-group mt-2">
                <label>{t('time_window_seconds')}</label>
                <input type="number" className="form-control mt-2" 
                  value={scrapper.period_seconds} onChange={e => setScrapper({ ...scrapper, period_seconds: e.target.value})} />
              </div>
              <div className="form-group mt-2">
                <label>{t('influxdb_credential')}</label>
                <Select options={influxDBCredentialNameList} 
                        onChange={(selected) => setScrapper({...scrapper, influxdb_credentials: selected.value}) }
                        className="company-site"
                        classNamePrefix="company-site"
                        styles={{
                          menuPortal: styles => ({...styles, zIndex: 10000 })
                        }}
                        menuPortalTarget={document.body} 
                        value={influxDBCredentialNameList.filter( item => item.value === scrapper.influxdb_credentials)} />
              </div>
              {scrapper.scrapper_type === 'scrapper_isolar' &&
              <>
                <div className="form-group mt-2">
                  <label>URL</label>
                  <input type="string" className="form-control mt-2" 
                    value={scrapper.configuration?.source_url} 
                    onChange={e => setScrapper({ ...scrapper, configuration: {...scrapper.configuration, source_url: e.target.value}})} />
                </div>
                <div className="form-group mt-2">
                  <label>Token</label>
                  <input type="string" className="form-control mt-2" 
                    value={scrapper.configuration?.source_token} 
                    onChange={e => setScrapper({ ...scrapper, configuration: {...scrapper.configuration, source_token: e.target.value}})} />
                </div>
                <div className="form-group mt-2">
                  <label>InfluxDB Org</label>
                  <input type="string" className="form-control mt-2" 
                    value={scrapper.configuration?.influxdb_org} 
                    onChange={e => setScrapper({ ...scrapper, configuration: {...scrapper.configuration, influxdb_org: e.target.value}})} />
                </div>
                <div className="form-group mt-2">
                  <label>InfluxDB Bucket</label>
                  <input type="string" className="form-control mt-2" 
                    value={scrapper.configuration?.influxdb_bucket} 
                      onChange={e => setScrapper({ ...scrapper, configuration: {...scrapper.configuration, influxdb_bucket: e.target.value}})} />
                </div>
              </>
              }
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary"
                onClick={() => props.onCancel()}>
                {t('cancel')}
              </button>
              <button type="button" className="standalone-button btn btn-primary"
                onClick={() => onSave()}>
                {t('save')}
              </button>
            </Modal.Footer>
          </Modal>)
}

export const Scrapper = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({ count: 0, results: []})
  const [loading, setLoading] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const targetUuid = useRef(null)
  const pageSizeRef = useRef(10);
  const offsetRef = useRef(0);
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showWarning, seShowWarning] = useState(false)
  const user = useSelector(state => state.main.user)
  const parameters = useRef({})
  const navigate = useNavigate();

  const headers = [
    { key: 'scrapper_type', title: t('name'), sort: SORT.DEFAULT },
    { key: 'period_seconds', title: t('period_seconds'), sort: SORT.DEFAULT, 
      rendering: (uuid, item) => {      
        return <div className='w-100 text-center'>
                  <div> {moment.duration(item, "seconds").format("h [hrs], m [min], s [sec]")}</div>
                  <div>[ {item} seconds ]</div>
                </div>
      }
    },
    { key: 'last_executed', title: t('last_execution'), sort: SORT.DEFAULT, className: 'text-center',
      rendering: (uuid, item) => {      
        return <div className='w-100 text-center'>
                  <span>{moment(item).format('DD/MM/YYYY HH:mm')}</span>
                </div>
        }
    },
    { key: 'last_executed', title: t('next_execution'), sort: SORT.DEFAULT, className: 'text-center',
      rendering: (uuid, item) => {      
        const dataLocal = data.results.find( item => item.uuid === uuid);
        return <div className='w-100 text-center'>
                  <span>{moment(item).add(dataLocal.period_seconds, 'seconds').format('DD/MM/YYYY HH:mm')}</span>
                </div>
      }
    },
    { key: 'influxdb_credentials', title: t('influxdb_credential'), sort: SORT.DEFAULT, className: 'text-center',
    rendering: (uuid, item) => {
        return <div className='text-center'>
                  <span className="badge bg-primary"  onClick={() => navigate( `${routes.influxdb_credential.url(item.server.uuid)}?uuid=${item.uuid}` )}>
                    <div>Name: {item.name}</div>
                    <div className='mt-1'>Server: {item.server.name}</div>                     
                  </span>
                </div>
      } 
    },
    { key: 'validated_source', title: t('validated_source'), sort: SORT.NONE, className: 'text-center',
    rendering: (uuid, item) => {
      return <div className='text-center'>
                {item == null && <FontAwesomeIcon className="ms-2" icon={faQuestion} />}
                {item === "KO" && <FontAwesomeIcon className="ms-2" icon={faTimes} />}
                {item === "OK" && <FontAwesomeIcon className="ms-2" icon={faCheck} />}
                {item === "LOADING" && <div className="spinner-border" role="status" style={{ width: '15px', height: '15px'}}></div>}
              </div>
    }
  }
  ]

  const refresh = async (limit, offset, parameters = {}) => {
    try{
      setLoading(true)
      const urlParams = new URLSearchParams(parameters).toString();
      const response = await axiosInstance.get(`/api/v1/scrappers/?expand=influxdb_credentials.server&limit=${limit}&offset=${offset}${urlParams.length>0?`&${urlParams}`:''}`);
      response.data.results = response.data.results.map( item => ({...item, validated_source: null}))
      setData(response.data)
      setLoading(false)
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  useEffect( () => {
    if( user )
      refresh(pageSizeRef.current, offsetRef.current)
  }, [user]);

  

  // -------------------
  // Handlers
  // -------------------

  const onPageChange = async (pageSize, page) => {
    // Store parameters
    pageSizeRef.current = pageSize;
    offsetRef.current = pageSize*page;
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSortClick = async (key, direction, pageSize, page) => {
    const prefix = direction === SORT.DOWN?"-":""
    parameters.current = { ...parameters.current, ordering : `${prefix}${key}` }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onSearch = (pattern, pageSize, page) => {
    parameters.current = { ...parameters.current, search : pattern }
    pageSizeRef.current = pageSize;
    offsetRef.current = page;
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
  }

  const onClickEdit = async (uuid, header) => {
    if(header.key === 'validated_source'){
      // Extract datalocal
      let dataLocal = JSON.parse(JSON.stringify(data))
      let item = dataLocal.results.find( item => item.uuid === uuid);
      item.validated_source = "LOADING"
      setData(dataLocal)


      dataLocal = JSON.parse(JSON.stringify(data))
      item = dataLocal.results.find( item => item.uuid === uuid);
      // Validate credentials
      try{
        await axiosInstance.post(`/api/v1/scrappers/${item.uuid}/validate/`)
        item.validated_source = "OK"
      }catch(e){
        console.log("e ", e)
        item.validated_source = "KO"
      }
      setData(dataLocal)
    
    }else{
      targetUuid.current = uuid;
      setShowDetail(true)
    }
  }

  const onClickDelete = (uuid) => {
    const obj = data.results.find( item => item.uuid === uuid );
    if(obj.calc_company_sites > 0 ){
      seShowWarning(true)
      return
    }

    // Proceed to delete
    targetUuid.current = uuid;
    setShowConfirmation(true)
  }

  const onDeleteProceed = async () => {
    try{
      setShowConfirmation(false)

      await axiosInstance.delete(`/api/v1/scrappers/${targetUuid.current}/`);

      // Refresh
      refresh(pageSizeRef.current, offsetRef.current, parameters.current)

      toast.success(t('request_succeded'))
    }catch(e){   
      toast.error(t('request_failed'))   
    }
  }

  const onClickAdd = () => {
    targetUuid.current = null;
    setShowDetail(true)
  }
  
  const onSaveCompleted = () => {
    // Refresh
    refresh(pageSizeRef.current, offsetRef.current, parameters.current)
    setShowDetail(false)
  }

  return <div className='h-100'>
          <Table data={data} headers={headers} 
            onSortClick={ (key, direction, pageSize, page) => onSortClick(key, direction, pageSize, page)} 
            onClickRow={(id, header) => onClickEdit(id, header)} 
            onClickDelete={(id) => onClickDelete(id)} 
            onClickAdd={() => onClickAdd()} 
            onPageChange={(pageSize, page) => onPageChange(pageSize, page)} 
            loading={loading} 
            onSearch={(pattern, pageSize, page) => onSearch(pattern, pageSize, page)} />

          <ModalConfirmation title={`${t('delete')} ${t('company')}`} 
            onCancel={() => setShowConfirmation(false)} 
            onProceed={() => onDeleteProceed()}
            show={showConfirmation} />

          <ModalWarning title={t('warning')}
            message={t('dependent_company_sites')}
            show={showWarning} 
            onProceed={() => seShowWarning(false)}
            />

          <ScrapperModal show={showDetail} 
            onCancel={() => setShowDetail(false)}
            onSave={() => onSaveCompleted()}
            targetUuid={targetUuid.current}
            />

        </div>    
  
}
