import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";

export const ModalConfirmation = (props) => {
  const { t } = useTranslation();
  return (<Modal show={props.show} onHide={() => props.onCancel()} centered>
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('are_you_sure')}
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary"
              onClick={() => props.onCancel()}>
              {t('cancel')}
            </button>
            <button type="button" className="standalone-button btn btn-primary"
              onClick={() => props.onProceed()}>
              {t('ok')}
            </button>
          </Modal.Footer>
        </Modal>)
}