import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactPaginate from 'react-paginate';
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import { faSort, faSortUp, faSortDown, faTimes } from '@fortawesome/free-solid-svg-icons'

export const SORT = { UP: 'UP', DOWN: 'DOWN', DEFAULT: 'DEFAULT', NONE: 'NONE' }



export const HeaderSort = (props) => {

  const [ headers, setHeaders ] = useState([])

  const onSortClick = (key) => {
    // Iterate on Sort Direction
    const headersLocal = JSON.parse(JSON.stringify(headers))
    const headerTarget = headersLocal.find( item => item.key === key)
    for( const header of headersLocal ){
      if( header.key === key || header.sort === SORT.NONE ) continue;
      header.sort = SORT.DEFAULT
    }

    if( headerTarget.sort === SORT.DEFAULT || headerTarget.sort === SORT.DOWN ) 
      headerTarget.sort = SORT.UP
    else if( headerTarget.sort === SORT.UP ) 
      headerTarget.sort = SORT.DOWN
    // else if( headerTarget.sort === SORT.DOWN ) headerTarget.sort = SORT.DEFAULT
    setHeaders( [ ...headersLocal ])

    if( props.onSortClick && headerTarget.sort !== SORT.NONE)
      props.onSortClick(key, headerTarget.sort)
  }

  useEffect( () => {
      setHeaders( props.headers )    
  }, [ props.headers ])

  const widthPerColumn = 100 / props.headers.length;

  return  <thead>
            <tr>
            {headers.map((item, idx) => 
            {
              let sortIcon = null
              if( item.sort === SORT.DEFAULT ) sortIcon = faSort
              if( item.sort === SORT.UP ) sortIcon = faSortUp
              if( item.sort === SORT.DOWN ) sortIcon = faSortDown

              const className = item.className?item.className:'text-left'
              return <th key={idx} className={idx<headers.length-1?className:"text-center"} 
                        style={{ width:  `${widthPerColumn}%`}}>
                        <div role="button" onClick={() => onSortClick(item.key)}>
                          <span>{item.title}</span>
                          <span>
                          {sortIcon?<FontAwesomeIcon className="ms-2" icon={sortIcon} />:null}
                          </span>
                        </div>
                      </th>
            }
          )}
          </tr>
        </thead>
}

// creating the custom useTimeout hook  
export const useTimeout = (callback, delay, reset) => { 
  // Creating a ref  
  const savedCallback = useRef(); 
  const idRef = useRef();


  // To remember the latest callback . 
  useEffect(() => { 
      savedCallback.current = callback; 
  }, [callback]); 

  function func() { 
    savedCallback.current(); 
  } 

  // combining the setInterval and  
  //clearInterval methods based on delay. 
  useEffect(() => { 
      // Only launch when resetToken > 0
      if(reset === 0 ) return;     
      // Generate setTimeout
      if (delay !== null) { 
          idRef.current = setTimeout(func, delay); 
          return () => clearTimeout(idRef.current); 
      } 
  }, [delay]); 

  useEffect( () => {
    // Only launch when resetToken > 0
    if(reset === 0 ) return;
    
    clearTimeout(idRef.current);    
    idRef.current = setTimeout(func, delay); 
    return () => clearTimeout(idRef.current); 
  }, [reset, delay])
}

export const TableLite = (props) => {
  const { t } = useTranslation();

  // Select whether to display 'Actions' column
  const actionsEnabled = props.actionsEnabled || props.actionsEnabled == null;
  const headersWithActions = [...props.headers, { key: 'actions', title: t('actions'), sort: SORT.NONE }]
  const headersWithoutActions = [...props.headers]
  const headers = useRef( actionsEnabled?headersWithActions:headersWithoutActions)

  return <div className='standalone-table-container'
          style={{ position: 'relative', ...props.style}}>

          {props.loading?
          <div className='d-flex justify-content-center align-items-center h-100 w-100 standalong-table-loading' 
            style={{ position: 'absolute', zIndex: 100, width: '100%', height: '100%', background: 'lightgrey', opacity: '0.5'}}>
            <div className="spinner-border" role="status" />
          </div>
          :null}

          <table className='standalone-table w-100'>
            <HeaderSort headers={headers.current} onSortClick={(key, direction) => props.onSortClick(key, direction)} />                
            <tbody>

                {props.data.results.map( (item,idx) => 
                  <tr key={idx}>
                    {props.headers.map( (header, idx) => {
                      if( header.rendering != null)
                        return <td key={idx} role="button" onClick={() => props.onClickRow(item.uuid, header)}>
                                  {header.rendering(item.uuid, item[header.key])}
                                </td>
                      

                      const className = header.className?header.className:''
                      return <td key={idx} role="button" onClick={() => props.onClickRow(item.uuid, header)}>
                          <div className={className} style={{ textWrap: 'normal', wordWrap: 'break-word'}}>
                            {item[header.key]}
                          </div>
                          
                        </td>
                      }
                    )}
                    {actionsEnabled?
                    <td className='text-center'>
                      <FontAwesomeIcon role="button" className="ms-2" icon={faTimes} 
                        style={{ color: 'red', fontSize: '20px'}} 
                        onClick={() => props.onClickDelete(item.uuid)} />
                    </td>
                    :null}
                  </tr>
                )}

                
              </tbody>
            </table>

        </div>
}

export const Table = (props) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const resetSearch = useRef(0)
  const [ searchPattern, setSearchPattern ] = useState('')

  const onSortClick = (key, direction) => {
    props.onSortClick(key, direction, pageSize, page)
    setPage(0)
  }

  const pageCount = Math.ceil( props.data.count / pageSize )

  const onPageChange = (e) => {
    props.onPageChange( pageSize, e.selected )
    setPage(e.selected)
  }

  const onPageSizechange = (e) => {
    props.onPageChange( e.target.value, 0 )
    setPage(0)
    setPageSize(e.target.value)
  }

  useTimeout( () =>{
    if( props.onSearch)
      props.onSearch( searchPattern, pageSize, page )
  }, 1000, resetSearch.current)

  const onChangeSearchPattern = (e) => {
    if( e.target.value.length > 3 || e.target.value.length === 0 )
      resetSearch.current = resetSearch.current + 1
    // Triggering search
    setPage(0)
    // Update pattern
    setSearchPattern(e.target.value, pageSize, page)
  }

  return <div className='d-flex flex-column' style={{ overflow: 'auto', minHeight: '100%', height: '0px'}}>
            <div className='mt-3 mb-3 d-flex justify-content-between'>
              <button type="button" className="btn btn-primary standalone-button"
                onClick={() => props.onClickAdd()}>{t('add_new')}</button>
              <input type="text" className="form-control mt-2" style={{ width: '300px'}} 
                placeholder={`${t('search')} ...`} onChange={(e) => onChangeSearchPattern(e)}/>
            </div>
            <div className='flex-grow-1'>
              <TableLite {...props} onSortClick={onSortClick} />
            </div>
            <div className='d-flex justify-content-end mt-3'>
              <div className='h-100'>
                <ReactPaginate
                  nextLabel=">"
                  onPageChange={(e) => onPageChange(e)}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="<"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="standalone-pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                  forcePage={page}
                />
              </div>
              <div className='ms-2 h-100 me-2'>
                <select className="form-select" 
                  style={{ width: '100px', fontSize: '12px'}}
                  onChange={(e) => onPageSizechange(e)}>
                  <option value="10">10 / page</option>
                  <option value="20">20 / page</option>
                  <option value="50">50 / page</option>
                  <option value="100">100 / page</option>
                </select>
              </div>
            </div>
          </div>
}
