import axios from 'axios'
import logoMain from '../assets/logoMain.png'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { setUser } from '../redux'
import { useDispatch } from 'react-redux'
import { getHeader } from '../common/utils';

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showWrongCredentials, setWrongCredentials] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogin = async () => {
    setLoading(true);
    try{
      // Wrong Credentials
      setWrongCredentials(false);
      // Login
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/login/`, {}, 
      { auth: { username, password} })

      
      // Store user
      localStorage.setItem('user', JSON.stringify(response.data) );
      
      // Navigate to dashboard
      navigate("/dashboard");
    }catch(e){
      console.log("login failed")
      // wrong credentials
      setWrongCredentials(true)
    }
    setLoading(false);
  }

  const onKeyUp = (e) => {
    if(e.key === 'Enter') onLogin()    
  }

  useEffect( () => {
    ( async () => {
      try{
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/users/me/`, { headers: getHeader() });
        dispatch( setUser( response.data ) ) 
        navigate("/dashboard");
      }catch(e){
        console.log("user not logged in")
      }      
    })();
  }, [])

  return <main className="d-flex vh-100 justify-content-center align-items-center">

    <div>
      <div className='bg-white d-flex justify-content-center align-items-center p-3' style={{ height: "200px"}}>
        {/* <img src={logoMain}  className='h-100' alt=''/> */}
        <h1 className="fw-bold" style={{ color: 'black'}}>STANDALONE</h1>
      </div>
      <div className="mb-3">
        <input type="email" className="form-control" placeholder="Please enter your username" 
          onChange={(e) => setUsername(e.target.value)}  onKeyUp={(e) => onKeyUp(e)}/>
        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
      </div>
      <div className="mb-3">
        <input type="password" className="form-control" placeholder="Please enter your password" 
          onChange={(e) => setPassword(e.target.value)} onKeyUp={(e) => onKeyUp(e)}/>
        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
      </div>
      <button type="button" className="standalone-button btn btn-primary w-100 d-flex justify-content-center align-items-center"
        onClick={() => onLogin()}>
        <span>Login</span>
        {loading?
        <div className="spinner-border spinner-border-sm ms-2" role="status" />
        :null}
      </button>

      {showWrongCredentials?
      <div class="alert alert-danger mt-3" role="alert">
        <div>Something goes wrong</div>
        <small>An error occurred. Please try again later</small>
      </div>
      :null}



    </div>
  </main>
}